<template>
  <div id="Question">
    <div class="mainTop" @click="path">
      <span>@</span>
      <div>{{name ? name :'邀请大咖为你解答'}}</div>
      <span class="right_arrow"></span>
    </div>

    <div style="border-bottom: 0.01rem solid #5C5D6E61;">
      <van-field
        v-model="question"
        rows="1"
        autosize
        type="textarea"
        placeholder="请输入问题（5~40字）"
        minlength="5"
        maxlength="40"
      />
    </div>
    <div >
      <van-field
        v-model="questionDescribe"
        rows="5"
        autosize
        type="textarea"
        placeholder="添加问题描述（对问题进行补充说明，获得更精准回答）"
      />
    </div>


    <div class="titleTab">
      <span>选择领域：</span>
      <div :class="{'active':tagId == i.id}" v-for="(i,item) in getTags" :key="item" @click="tagId = i.id">{{i.name}}</div>
    </div>

    <div class="toSubmit" @click="toSub">提交提问</div>
  </div>
</template>
<script>
export default {
  name: 'Question',
   data(){
    return{
      question:'',
      questionDescribe:'',
      getTags:'',
      tagId:'',//标签id
      name:'',
      answerId:'',//大咖id
    }
  },
  mounted(){
    this.name = this.$route.query.name
    this.answerId = this.$route.query.id
    // 话题分类
    this.axios({
      method: 'GET',
      url: '/quiz/getTags',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.tagId = res.data.data[0].id
        this.getTags = res.data.data
      }
    })
    
  },
  methods:{
    toSub(){
      if(!this.answerId){
        this.$toast('请先邀请大咖')
        return
      }
      if(!this.question){
        this.$toast('请输入问题')
        return
      }
      if(this.question.length<5){
        this.$toast('问题最小需要5个字')
        return
      }if(this.question.length>40){
        this.$toast('问题长度最大不超过40个字')
        return
      }
      this.axios({
        method: 'POST',
        url: '/quiz/addQuiz',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          question:this.question,
          tagId:this.tagId,
          answerId:this.answerId,
          questionDescribe:this.questionDescribe,

        }
      }).then((res) => {
        if(res.data.code == 0){
          this.$toast('提问成功！')
          var _this = this;
          setTimeout(function(){
            _this.$router.push('/FindOut')
          },2000)
        }else{
          this.$toast(res.data.message)
        }
      })
    },
    path(){
      this.$router.push({
        path: '/findConnections',
      })
    }
  }
  
}
</script>
<style lang="less" scoped>
#Question{
  >div{
    background: #23252E;
  }
  .toSubmit{
    height: 0.42rem;
    background: #3890FF;
    opacity: 1;
    border-radius: 5px;
    color: #F6F7FD;
    line-height: 0.42rem;
    margin: 0.2rem 0.15rem;
    text-align: center;
  }
  .titleTab{
    margin-top: 0.15rem;
    padding: 0.2rem 0.15rem 0.1rem;
    display: flex;
    flex-wrap: wrap;
    >span{
      color: #727387;
      font-size: 0.12rem;
      height: 0.25rem;
      line-height: 0.26rem;
    }
    >div{
      display: inline-block;
      height: 0.25rem;
      border-radius: 0.25rem;
      padding: 0 0.12rem;
      line-height: 0.26rem;
      background: #3D404F;
      color: #989AB1;
      font-size: 0.12rem;
      margin-right: 0.12rem;
      margin-bottom: 0.1rem;
    }
    .active{
      background: #3890FF;
      color: #F6F7FD;
    }
  }
  .mainTop{
    margin-bottom: 0.1rem;
    padding: 0.15rem;
    display: flex;
    align-items: center;
    height: 0.16rem;
    >span:first-child{
      color: #3890FF;
      margin-right: 0.06rem;
    }
    >div{
      flex: 1;
      color: #989AB1;
      font-size: 0.12rem;
    }
    .right_arrow {
      width: 0.06rem;
      height: 0.06rem;
      border-top: 1px solid #CFD0E0;
      border-right: 1px solid #CFD0E0;
      transform: rotate(45deg);
    } 
  }
}
</style>